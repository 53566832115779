import React from 'react'
import isPropValid from '@emotion/is-prop-valid'
import css from '@emotion/css'
import { LanguageContext } from 'src/components/Language'

//English SVG version of Sustainable Development Goals
import NoPovertySVG from 'assets/svgs/globalGoals/en/1-no-poverty.inline.svg'
import ZeroHungerSVG from 'assets/svgs/globalGoals/en/2-zero-hunger.inline.svg'
import GoodHealthAndWellBeingSVG from 'assets/svgs/globalGoals/en/3-good-health-and-well-being.inline.svg'
import QualityEducationSVG from 'assets/svgs/globalGoals/en/4-quality-education.inline.svg'
import GenderEqualitySVG from 'assets/svgs/globalGoals/en/5-gender-equality.inline.svg'
import CleanWaterAndSanitationSVG from 'assets/svgs/globalGoals/en/6-clean-water-and-sanitation.inline.svg'
import AffordableAndCleanEnergySVG from 'assets/svgs/globalGoals/en/7-affordable-and-clean-energy.inline.svg'
import DecentWorkAndEconomicGrowthSVG from 'assets/svgs/globalGoals/en/8-decent-work-and-economic-growth.inline.svg'
import IndustryInnovationAndInfrastructureSVG from 'assets/svgs/globalGoals/en/9-industry-innovation-and-infrastructure.inline.svg'
import ReducedInequalitiesSVG from 'assets/svgs/globalGoals/en/10-reduced-inequalities.inline.svg'
import SustainableCitiesAndCommunitiesSVG from 'assets/svgs/globalGoals/en/11-sustainable-cities-and-communities.inline.svg'
import ResponsibleConsumptionAndProductionSVG from 'assets/svgs/globalGoals/en/12-responsible-consumption-and-production.inline.svg'
import ClimateActionSVG from 'assets/svgs/globalGoals/en/13-climate-action.inline.svg'
import LifeBelowWaterSVG from 'assets/svgs/globalGoals/en/14-life-below-water.inline.svg'
import LifeOnLandSVG from 'assets/svgs/globalGoals/en/15-life-on-land.inline.svg'
import PeaceJusticeAndStrongInstitutionsSVG from 'assets/svgs/globalGoals/en/16-peace-justice-and-strong-institutions.inline.svg'
import PartnershipsForTheGoalsSVG from 'assets/svgs/globalGoals/en/17-partnerships-for-the-goals.inline.svg'

//Spanish SVG version of Sustainable Development Goals
//I'm doing this in a less than elegant way because dynamic imports are not possible without re-engineering this component.
//As of right now, we are only dealing with two languages. If a 3rd is added, it will then be worth the lift to re-engineer.
import NoPovertySVG_es from 'assets/svgs/globalGoals/es/1-no-poverty.inline.svg'
import ZeroHungerSVG_es from 'assets/svgs/globalGoals/es/2-zero-hunger.inline.svg'
import GoodHealthAndWellBeingSVG_es from 'assets/svgs/globalGoals/es/3-good-health-and-well-being.inline.svg'
import QualityEducationSVG_es from 'assets/svgs/globalGoals/es/4-quality-education.inline.svg'
import GenderEqualitySVG_es from 'assets/svgs/globalGoals/es/5-gender-equality.inline.svg'
import CleanWaterAndSanitationSVG_es from 'assets/svgs/globalGoals/es/6-clean-water-and-sanitation.inline.svg'
import AffordableAndCleanEnergySVG_es from 'assets/svgs/globalGoals/es/7-affordable-and-clean-energy.inline.svg'
import DecentWorkAndEconomicGrowthSVG_es from 'assets/svgs/globalGoals/es/8-decent-work-and-economic-growth.inline.svg'
import IndustryInnovationAndInfrastructureSVG_es from 'assets/svgs/globalGoals/es/9-industry-innovation-and-infrastructure.inline.svg'
import ReducedInequalitiesSVG_es from 'assets/svgs/globalGoals/es/10-reduced-inequalities.inline.svg'
import SustainableCitiesAndCommunitiesSVG_es from 'assets/svgs/globalGoals/es/11-sustainable-cities-and-communities.inline.svg'
import ResponsibleConsumptionAndProductionSVG_es from 'assets/svgs/globalGoals/es/12-responsible-consumption-and-production.inline.svg'
import ClimateActionSVG_es from 'assets/svgs/globalGoals/es/13-climate-action.inline.svg'
import LifeBelowWaterSVG_es from 'assets/svgs/globalGoals/es/14-life-below-water.inline.svg'
import LifeOnLandSVG_es from 'assets/svgs/globalGoals/es/15-life-on-land.inline.svg'
import PeaceJusticeAndStrongInstitutionsSVG_es from 'assets/svgs/globalGoals/es/16-peace-justice-and-strong-institutions.inline.svg'
import PartnershipsForTheGoalsSVG_es from 'assets/svgs/globalGoals/es/17-partnerships-for-the-goals.inline.svg'

import { GlobalGoal } from 'src/graphql-generated'
import styled from 'src/styles/styled'
import { theme } from 'src/styles/theme'

const cardHover = (color: string) => css`
    &:hover {
      color: ${color};
      border-color ${color};
      background: ${theme.colors.white};
      pointer: cursor;
    }
`

const createComponent = (
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  color: string
) =>
  styled<'svg', { invert: boolean; asCard: boolean; clickable: boolean }>(
    svg as any,
    {
      shouldForwardProp: isPropValid,
    }
  )`
    color: ${color};
    background: ${props => props.theme.colors.white};
    fill: currentColor;
    box-shadow: ${props =>
      props.asCard ? '2px 5px 12px 0 rgba(0, 0, 0, 0.04)' : undefined};
    border-radius: ${props => (props.asCard ? '4px' : undefined)};
    border: ${props => (props.asCard ? 'solid 2px' : undefined)};
    border-color: ${props =>
      props.invert ? color : props.theme.colors.lightGrey};
    ${props => props.clickable && cardHover(color)};
  `

const svgMap = {
  [GlobalGoal.NoPoverty]: createComponent(NoPovertySVG, '#e5243b'),
  [GlobalGoal.ZeroHunger]: createComponent(ZeroHungerSVG, '#dda63a'),
  [GlobalGoal.GoodHealthAndWellBeing]: createComponent(
    GoodHealthAndWellBeingSVG,
    '#4C9F38'
  ),
  [GlobalGoal.QualityEducation]: createComponent(
    QualityEducationSVG,
    '#C5192D'
  ),
  [GlobalGoal.GenderEquality]: createComponent(GenderEqualitySVG, '#FF3A21'),
  [GlobalGoal.CleanWaterAndSanitation]: createComponent(
    CleanWaterAndSanitationSVG,
    '#26BDE2'
  ),
  [GlobalGoal.AffordableAndCleanEnergy]: createComponent(
    AffordableAndCleanEnergySVG,
    '#FCC30B'
  ),
  [GlobalGoal.DecentWorkAndEconomicGrowth]: createComponent(
    DecentWorkAndEconomicGrowthSVG,
    '#A21942'
  ),
  [GlobalGoal.IndustryInnovationAndInfrastructure]: createComponent(
    IndustryInnovationAndInfrastructureSVG,
    '#FD6925'
  ),
  [GlobalGoal.ReducedInequalities]: createComponent(
    ReducedInequalitiesSVG,
    '#DD1367'
  ),
  [GlobalGoal.SustainableCitiesAndCommunities]: createComponent(
    SustainableCitiesAndCommunitiesSVG,
    '#FD9D24'
  ),
  [GlobalGoal.ResponsibleConsumptionAndProduction]: createComponent(
    ResponsibleConsumptionAndProductionSVG,
    '#BF8B2E'
  ),
  [GlobalGoal.ClimateAction]: createComponent(ClimateActionSVG, '#3F7E44'),
  [GlobalGoal.LifeBelowWater]: createComponent(LifeBelowWaterSVG, '#0A97D9'),
  [GlobalGoal.LifeOnLand]: createComponent(LifeOnLandSVG, '#56C02B'),
  [GlobalGoal.PeaceJusticeAndStrongInstitutions]: createComponent(
    PeaceJusticeAndStrongInstitutionsSVG,
    '#00689D'
  ),
  [GlobalGoal.PartnershipsForTheGoals]: createComponent(
    PartnershipsForTheGoalsSVG,
    '#19486A'
  ),
}

const svgMap_es = {
  [GlobalGoal.NoPoverty]: createComponent(NoPovertySVG_es, '#e5243b'),
  [GlobalGoal.ZeroHunger]: createComponent(ZeroHungerSVG_es, '#dda63a'),
  [GlobalGoal.GoodHealthAndWellBeing]: createComponent(
    GoodHealthAndWellBeingSVG_es,
    '#4C9F38'
  ),
  [GlobalGoal.QualityEducation]: createComponent(
    QualityEducationSVG_es,
    '#C5192D'
  ),
  [GlobalGoal.GenderEquality]: createComponent(GenderEqualitySVG_es, '#FF3A21'),
  [GlobalGoal.CleanWaterAndSanitation]: createComponent(
    CleanWaterAndSanitationSVG_es,
    '#26BDE2'
  ),
  [GlobalGoal.AffordableAndCleanEnergy]: createComponent(
    AffordableAndCleanEnergySVG_es,
    '#FCC30B'
  ),
  [GlobalGoal.DecentWorkAndEconomicGrowth]: createComponent(
    DecentWorkAndEconomicGrowthSVG_es,
    '#A21942'
  ),
  [GlobalGoal.IndustryInnovationAndInfrastructure]: createComponent(
    IndustryInnovationAndInfrastructureSVG_es,
    '#FD6925'
  ),
  [GlobalGoal.ReducedInequalities]: createComponent(
    ReducedInequalitiesSVG_es,
    '#DD1367'
  ),
  [GlobalGoal.SustainableCitiesAndCommunities]: createComponent(
    SustainableCitiesAndCommunitiesSVG_es,
    '#FD9D24'
  ),
  [GlobalGoal.ResponsibleConsumptionAndProduction]: createComponent(
    ResponsibleConsumptionAndProductionSVG_es,
    '#BF8B2E'
  ),
  [GlobalGoal.ClimateAction]: createComponent(ClimateActionSVG_es, '#3F7E44'),
  [GlobalGoal.LifeBelowWater]: createComponent(LifeBelowWaterSVG_es, '#0A97D9'),
  [GlobalGoal.LifeOnLand]: createComponent(LifeOnLandSVG_es, '#56C02B'),
  [GlobalGoal.PeaceJusticeAndStrongInstitutions]: createComponent(
    PeaceJusticeAndStrongInstitutionsSVG_es,
    '#00689D'
  ),
  [GlobalGoal.PartnershipsForTheGoals]: createComponent(
    PartnershipsForTheGoalsSVG_es,
    '#19486A'
  ),
}

interface Props {
  globalGoal: GlobalGoal
  width?: string | number
  height?: string | number
  invert?: boolean
  asCard?: boolean
  clickable?: boolean
}

const GlobalGoalIcon: React.FunctionComponent<Props> = ({
  globalGoal,
  invert = false,
  asCard = false,
  clickable = false,
  ...props
}) => {
  const languageContext = React.useContext(LanguageContext)

  const Icon =
    languageContext.currentUILanguage === 'es'
      ? svgMap_es[globalGoal]
      : svgMap[globalGoal]
  /* TODO: make accessible */
  return (
    <Icon {...props} invert={invert} asCard={asCard} clickable={clickable} />
  )
}

export default GlobalGoalIcon
